<template>
	<div class="page_box">

		<div class="bom_box">

			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading">
					<!-- 暂时无需 -->
					<!-- <el-table-column type="selection" width="55"></el-table-column> -->

					<el-table-column fixed prop="name" label="姓名" width="130">
						<template slot-scope="scope">
							<el-popover trigger="hover" placement="top" :content="scope.row.name">
								<div slot="reference" class="name-wrapper" style="color: #2373C8;">
									{{ scope.row.name }}
								</div>
							</el-popover>
						</template>
					</el-table-column>

					<el-table-column prop="order_no" label="调令号" width="200"></el-table-column>
					<el-table-column prop="q_bm_title" label="调动前部门" width="150"></el-table-column>
					<el-table-column prop="q_gw_title" label="调动前岗位" width="200"></el-table-column>
					<el-table-column prop="h_bm_title" label="调动后部门" width="180"></el-table-column>
					<el-table-column prop="h_gw_title" label="调动后岗位" width="180"></el-table-column>
					<el-table-column prop="xf_time" label="调令下发日期" width="180"></el-table-column>
					<el-table-column prop="bd_time" label="报道日期"></el-table-column>
					<!-- 操作模块   -->
					<el-table-column label="操作" fixed="right"  width="100">
						<template slot-scope="scope">
                            <div class="scope_btns">
                                <el-button type="text" @click="handle_see( scope.row)">查看调令</el-button>
                            </div>
						</template>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
		<add_dl ref="add_dl"></add_dl>

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的

	import add_dl from "@/components/diaoling/add_diaoling.vue"; //新增凋零

	export default {
		components: {
			page,
			add_dl
		},
		data() {
			return {
				loading: false, //加载总
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
					identity: '',
				},
				list: [],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
		 
			 
			};
		},

		methods: {
			add_dling() {
				this.$refs.add_dl.init()
			},
            handle_see(row){
                window.open(row.pdf.path)
            },
			 
			 

			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("getUserRedeployList", {
					// mobile:this.mobile
					// ...this.forms,
					...this.pages,
					userId:this.$route.query.id
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
						this.list.forEach((e)=>{
							if(e.pdf){
								e.pdf=JSON.parse(e.pdf)
							}
						})
					} else {
						alertErr(res.msg)
					}
				});
			},

		},
		computed: {},
		watch: {},
		created() {
			this.get_list()
			
		},
		mounted() {

		}


	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;
		background: #fff;

		.bom_box {
			.bom_box_list {
				padding: 20px 20px 32px 20px;
				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>